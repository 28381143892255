
.invalid-star {
  display: block !important;
  width: 100% !important;
  margin-top: -3px !important;
  font-size: 20px !important;
  height: 17px !important;
  color: #f64e60 !important;
  margin-left: 1.75rem !important;
  margin-bottom: 0.25rem !important;
}
.icon{
  color: #6959FC;
}
.deleteIcon{
  color: #f64e60;
}
.lockIcon{
  color: #4BB543;
}
.text-delete {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #100b00 !important;
}
.btn-delete {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  padding: 12px 20px;
  cursor: pointer;
  margin-right: 16px;
}
.delete-font {
  font-size: 22px;
  color: #ff5a5a;

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}

.invalid-feedback {
  display: block !important;
  width: 100% !important;
  font-size: 12px !important;
  height: 17px !important;
  color: #f64e60 !important;
  margin-bottom: 0.25rem !important;
}
.searchText{
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid #ebedf3;
}


.invalid-starModal {
  display: block !important;
  width: 100% !important;
  margin-top: -3px !important;
  font-size: 20px !important;
  height: 17px !important;
  color: #f64e60 !important;
  margin-left: 0.5rem !important;
  margin-bottom: 0.25rem !important;
}

.viewIcon{
  color: #6959FC;
}
.fs-14{
  font-size: 14px;
}
.sliderImage{
  min-height: 100%;
    min-width: 100%;
    height: 100vh;
    object-fit: contain;
}
.text-black{
  color: #100b00;
}
.carousel-indicators li{
  background-color: #100b00;

}
.carousel-control-prev-icon {
   
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23100b00%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath d=%27M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z%27/%3e%3c/svg%3e");
    
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23100b00%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath d=%27M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z%27/%3e%3c/svg%3e");
}